/*import React from 'react';
import { graphql } from 'gatsby';
import { HomeData } from '@/types';
import useLoadStructure from '@/hooks/useLoadStructure';
import useScrollNavigation from '@/hooks/useScrollNavigation';
import useHomeData from '@/hooks/useHomeData';
import { app } from '@/utils/app';
import Index from '@/components/pages';

type Props = {
  data: HomeData;
};

function Homepage({ data }: Props) {
  const {
    seo,
    navigationItems,
    home,
    marchio,
    manifesto,
    ideazione,
    prodotti,
    emersivi,
    designers,
    team,
    info,
    modal,
  } = useHomeData(data);

  const [scrollTo] = useScrollNavigation(2);

  app.structure = useLoadStructure({
    navigation: {
      main: navigationItems,
      designers: designers.list,
      products: prodotti.items,
    },
  });

  

  return (
    <Index
      seo={seo}
      navigation={app.structure.navigation}
      footerIcons={[]}
      footerLink={{ label: ``, url: `` }}
      home={home}
      marchio={marchio}
      manifesto={manifesto}
      ideazione={ideazione}
      prodotti={prodotti}
      emersivi={emersivi}
      designers={designers}
      team={team}
      info={info}
      modal={modal}
      navigationReady={app.structure.navigationReady}
      
      scrollTo={scrollTo}
    />
  );
}

export const query = graphql`
  query {
    strapiMainSeo {
      ...StrapiSeo
    }
    strapiMenu {
      ...StrapiMenu
    }

    strapiHome {
      ...StrapiHome
    }

    strapiMarchio {
      ...StrapiMarchio
    }

    strapiManifesto {
      ...StrapiManifesto
    }

    strapiIdeazione {
      ...StrapiIdeazione
    }

    strapiProdotti {
      ...StrapiProdotti
    }

    strapiTeam {
      ...StrapiTeam
    }

    strapiDesigner {
      ...StrapiDesigner
    }

    strapiEmersivi {
      ...StrapiEmersivi
    }

    strapiInfo {
      ...StrapiInfo
    }

    strapiModal {
      ...StrapiModal
    }
  }
`;

export default Homepage;*/

import React from 'react';
import { graphql } from 'gatsby';
import { HomeData } from '@/types';
import useLoadStructure from '@/hooks/useLoadStructure';
import useScrollNavigation from '@/hooks/useScrollNavigation';
import useHomeData from '@/hooks/useHomeData';
import { app } from '@/utils/app';
import loadable from '@loadable/component'; // Lazy Loading per ridurre il bundle

// Lazy Load per il componente Index (quello che pesa di più)
const Index = loadable(() => import(`@/components/pages`), { ssr: false });

type Props = {
  data: HomeData;
};

function Homepage({ data }: Props) {
  const {
    seo,
    navigationItems,
    home,
    marchio,
    manifesto,
    ideazione,
    prodotti,
    emersivi,
    designers,
    team,
    info,
    modal,
  } = useHomeData(data);

  const [scrollTo] = useScrollNavigation(2);

  app.structure = useLoadStructure({
    navigation: {
      main: navigationItems,
      designers: designers.list,
      products: prodotti.items,
    },
  });

  return (
    <Index
      seo={seo}
      navigation={app.structure.navigation}
      footerIcons={[]}
      footerLink={{ label: ``, url: `` }}
      home={home}
      marchio={marchio}
      manifesto={manifesto}
      ideazione={ideazione}
      prodotti={prodotti}
      emersivi={emersivi}
      designers={designers}
      team={team}
      info={info}
      modal={modal}
      navigationReady={app.structure.navigationReady}
      scrollTo={scrollTo}
    />
  );
}

// Ottimizzazione GraphQL: Carica solo i campi necessari
export const query = graphql`
  query {
    strapiMainSeo {
      ...StrapiSeo
    }
    strapiMenu {
      ...StrapiMenu
    }
    strapiHome {
      ...StrapiHome
    }
    strapiMarchio {
      ...StrapiMarchio
    }
    strapiManifesto {
      ...StrapiManifesto
    }
    strapiIdeazione {
      ...StrapiIdeazione
    }
    strapiProdotti {
      ...StrapiProdotti
    }
    strapiTeam {
      ...StrapiTeam
    }
    strapiDesigner {
      ...StrapiDesigner
    }
    strapiEmersivi {
      ...StrapiEmersivi
    }
    strapiInfo {
      ...StrapiInfo
    }
    strapiModal {
      ...StrapiModal
    }
  }
`;

export default Homepage;

/*import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { HomeData } from '@/types';
import useLoadStructure from '@/hooks/useLoadStructure';
import useScrollNavigation from '@/hooks/useScrollNavigation';
import useHomeData from '@/hooks/useHomeData';
import { app } from '@/utils/app';
import loadable from '@loadable/component'; // Lazy Loading per ridurre il bundle

// ✅ Lazy Load del componente Index con Fallback
const Index = loadable(() => import('@/components/pages'), {
  ssr: false, // Disabilita SSR solo per questo componente
  fallback: <div className="loading-placeholder" />,
});

type Props = {
  data: HomeData;
};

function Homepage({ data }: Props) {
  const {
    seo,
    navigationItems,
    home,
    marchio,
    manifesto,
    ideazione,
    prodotti,
    emersivi,
    designers,
    team,
    info,
    modal,
  } = useHomeData(data);

  const [scrollTo] = useScrollNavigation(2);

  // ✅ Preload del componente Index quando l'utente inizia a scorrere o muove il mouse
  useEffect(() => {
    const handlePreload = () => {
      Index.preload();
    };

    window.addEventListener('scroll', handlePreload, { once: true });
    window.addEventListener('mousemove', handlePreload, { once: true });

    return () => {
      window.removeEventListener('scroll', handlePreload);
      window.removeEventListener('mousemove', handlePreload);
    };
  }, []);

  app.structure = useLoadStructure({
    navigation: {
      main: navigationItems,
      designers: designers.list,
      products: prodotti.items,
    },
  });

  return (
    <Index
      seo={seo}
      navigation={app.structure.navigation}
      footerIcons={[]}
      footerLink={{ label: '', url: '' }}
      home={home}
      marchio={marchio}
      manifesto={manifesto}
      ideazione={ideazione}
      prodotti={prodotti}
      emersivi={emersivi}
      designers={designers}
      team={team}
      info={info}
      modal={modal}
      navigationReady={app.structure.navigationReady}
      scrollTo={scrollTo}
    />
  );
}

// ✅ Ottimizzazione GraphQL: Carica solo i campi necessari
export const query = graphql`
  query {
    strapiMainSeo {
      ...StrapiSeo
    }
    strapiMenu {
      ...StrapiMenu
    }
    strapiHome {
      ...StrapiHome
    }
    strapiMarchio {
      ...StrapiMarchio
    }
    strapiManifesto {
      ...StrapiManifesto
    }
    strapiIdeazione {
      ...StrapiIdeazione
    }
    strapiProdotti {
      ...StrapiProdotti
    }
    strapiTeam {
      ...StrapiTeam
    }
    strapiDesigner {
      ...StrapiDesigner
    }
    strapiEmersivi {
      ...StrapiEmersivi
    }
    strapiInfo {
      ...StrapiInfo
    }
    strapiModal {
      ...StrapiModal
    }
  }
`;

export default Homepage;*/
